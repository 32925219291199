export const apartmentSEO = {
  3652369058: {
    title: "Apartment 9B | Spacious and Modern Retreat in Tirana",
    description:
      "Discover Apartment 9B at By Best Apartments, a spacious and modern retreat in the heart of Tirana, perfect for your stay.",
    url: "https://metrosuites.al/rental/3652369058",
  },
  1674685192: {
    title: "Apartment 9A | Spacious, Elegant, and Central in Tirana",
    description:
      "Discover Apartment 9A at By Best Apartments, featuring spacious interiors, elegant design, and a central location for your perfect Tirana stay.",
    url: "https://metrosuites.al/rental/1674685192",
  },
  2176163366: {
    title: "Apartment 8A | Cozy and Comfortable Stay in Tirana",
    description:
      "Experience comfort and coziness at Apartment 8A, offering a delightful stay in the heart of Tirana.",
    url: "https://metrosuites.al/rental/2176163366",
  },
  2338306870: {
    title: "Apartment 8B | Stylish and Convenient Accommodation in Tirana",
    description:
      "Apartment 8B at By Best Apartments offers stylish and convenient accommodation for travelers seeking comfort in Tirana.",
    url: "https://metrosuites.al/rental/2338306870",
  },
  5535476811: {
    title: "Apartment 8C | Modern Living Space in Central Tirana",
    description:
      "Stay in Apartment 8C, a modern living space in central Tirana, ideal for both relaxation and exploration.",
    url: "https://metrosuites.al/rental/5535476811",
  },
};
