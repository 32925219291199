import React from "react";
import { useLocalStorage } from "../../services/hooks/use-localstorage";
import { useLogout } from "../../services/hooks/use-logout";
import { customerText } from "../Seo/whitelabel/bybest/bybest-customer-layout";
import { links } from "./data";
import { logoutIcon } from "./icons";
import "./index.css";
import { SidebarLink } from "./sidebar-link";
import TierLevel from "./tier-level";

const Sidebar = ({
  selected,
  open = false,
  setOpen,
  navLinks,
  showTier = true,
  containerClassNames,
  ativeLinkClassNames,
  prefix,
  tokenName,
  redirectOnLogout,
}) => {
  const handleLogout = useLogout(false, tokenName, redirectOnLogout);
  const [authResponse] = useLocalStorage("authResponse", null);

  const userData = authResponse?.user;

  const getInitials = (name) => {
    if (!name) return "";
    const initials = name.split(" ").map((word) => word[0].toUpperCase());
    return initials.join("");
  };

  return (
    <div
      style={{ height: "calc(100vh - 80px)" }}
      className={`sidebar overflow-y-auto lg:w-[290px] bg-white md:flex flex-col ${
        open ? "fixed top-28 left-2 bg-white h-screen z-50" : "hidden"
      } ${containerClassNames ?? ""}`}
    >
      <div className="user-profile mt-2 flex items-center !gap-0 ml-4">
        {userData?.name ? (
          <div className="avatar-initials w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center text-white text-2xl font-bold">
            {getInitials(userData.name)}
          </div>
        ) : (
          <div className="w-16 h-16 rounded-full bg-gray-300"></div>
        )}
        <div className="user-name ml-4">
          <h1>{userData?.name}</h1>
          <p className="text-[#666A73] text-14 ">
            {customerText("loyaltyBonus")}: {userData?.walletBalance}
          </p>
        </div>
      </div>

      <TierLevel className="w-full p-4" level={userData?.currentTierName} />

      <div
        className={`flex flex-col h-full gap-5 justify-between w-full ${
          open ? "" : "flex-1"
        }`}
      >
        <div className="sidebar-item-list w-full">
          {(navLinks ? navLinks(selected) : links(selected)).map((link) => (
            <SidebarLink
              {...link}
              linkKey={link.key}
              selected={selected === link.key}
              setOpen={setOpen}
              ativeLinkClassNames={ativeLinkClassNames}
              prefix={prefix}
            />
          ))}
        </div>

        <div className="px-4">
          <SidebarLink
            icon={logoutIcon}
            label={customerText("sidebar.signout")}
            onItemClick={handleLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
