/* eslint-disable react-hooks/exhaustive-deps */
import CircularProgress from "@mui/material/CircularProgress";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FormText from "../../components/FormText";
import StripeCheckoutForm from "../../components/StripeCheckout";
import { STRIPE_PUB_KEY } from "../../constants/config";
import WhiteLabelService from "../../services/whiteLabelService";
import { identifyVenue, trackEvent } from "../../utils/mixpanelUtil";
import { KEYS, getStorageKey } from "../../utils/storage";
import { getAccommodationText } from "../venue-accommodation";
import RentalBooking from "./booking";
import "./index.css";
import RentalReserveModal from "./rental-reserve-modal";
import { validator } from "./validator";
import { ACCOMMODATION_ID } from "../../components/Messages/react-web-gifted-chat/Constant";
import { t } from "i18next";

export const rentalReserveText = (label = "") => t(`rental.${label}`);

const RentalReserve = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const user = useSelector((state) => state?.auth?.user);

  const { venue_app_key: app_key } = useSelector((state) => state?.cart);

  const dataKey = localStorage.getItem("venue_key1") ?? ACCOMMODATION_ID;

  const [pay, setPay] = useState("later");
  const formikProps = useFormik({
    initialValues: {
      first_name: user ? user.name.split(" ")[0] : "",
      last_name: user ? user.name.split(" ")[1] : "",
      phone: user ? user.phone : "",
      email: user ? user.email : "",
      address_line1: "",
      address_line2: "",
      state: "New York",
      city: "",
      postcode: "",
      company_name: "",
      additional_notes: "",
    },
    validationSchema: validator,
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: true,
  });

  const [editGuest, setEditGuest] = useState(false);
  const [guestCnt, setGuestCnt] = useState(state?.guestNo);
  const [days, setDays] = useState(state?.days);
  const [subTotal, setSubTotal] = useState(state?.total);
  const [checkIn, setCheckIn] = useState(state?.checkInDate);
  const [checkOut, setCheckOut] = useState(state?.checkOutDate);
  const [selectedGuests, setSelectedGuests] = useState(state?.selectedGuests);
  const [modalCheckIn, setModalCheckIn] = useState(state?.modalCheckIn);
  const [modalCheckOut, setModalCheckOut] = useState(state?.modalCheckOut);
  const [openModal, setOpenModal] = useState(false);
  const [show, setShow] = useState(true);
  const [updatedPrice, setUpdatedPrice] = useState(state?.price);
  let [discountPrice, setDiscountPrice] = useState(state?.discountValue);
  const [totalDiscount, setTotalDiscount] = useState(state?.discountValue);
  const [total, setTotal] = useState(state?.total);
  const [paymentSubmitLoader, setPaymentSubmitLoader] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [discount_id, setDiscount] = useState(state?.discount_id);
  const [bookingDate, setBookingDate] = useState(state?.bookingDate);

  const stripePromise = loadStripe(STRIPE_PUB_KEY);

  const userData = getStorageKey(KEYS.USER_DATA);

  function extractUserInformation(userObject) {
    const { name, email } = userObject;
    const [firstName, ...lastNameParts] = name.split(" ");
    const surname = lastNameParts.join(" ");

    return {
      first_name: firstName,
      last_name: surname,
      email,
      phone: userObject?.guest?.phone ?? "",
    };
  }

  useEffect(() => {
    const userData = getStorageKey(KEYS.USER_DATA);
    if (userData && formikProps) {
      for (const [key, value] of Object.entries(
        extractUserInformation(userData)
      )) {
        formikProps.setFieldValue(key, value);
      }
    }
  }, []);

  const handlePaymentChange = async () => {
    try {
      const stripeTotal = Math.round(
        (Number(total) > 0 ? Number(total) : 1) * 100
      );

      const payload = {
        amount: stripeTotal,
        guest_id: user?.id,
        description: `Booking Payment for Rental Unit: ${state?.rentalName}`,
        currency: state?.currency === "€" ? "eur" : "usd",
      };
      const response = await WhiteLabelService.createDestinationCharge(
        payload,
        dataKey
      );
      setClientSecret(response.data.client_secret);
      identifyVenue(dataKey);
      trackEvent("Stripe Payment Intent", {
        action_category: "stripe create payment intent",
        action_outcome: "success",
        interaction_element: "api",
        source: "vb_web",
      });
    } catch (err) {
      NotificationManager.error(
        err?.error ?? rentalReserveText("went_wrong_payment")
      );
      identifyVenue(dataKey);
      trackEvent("Stripe Payment Intent", {
        action_category: "stripe create payment intent",
        action_outcome: "fail",
        interaction_element: "api",
        source: "vb_web",
      });
    } finally {
      // setIsLoading(false);
    }
  };

  const isDisabled = userData
    ? false
    : !formikProps.values.first_name ||
      !formikProps.values.last_name ||
      !formikProps.values.email ||
      !formikProps.values.phone;

  const getPriceValue = (guestNum) => {
    return state?.pricePerNight.find(
      (item) => Number(item.nr_guests) === Number(guestNum)
    );
  };

  const formatDate = (inputDate) => {
    const date = moment(new Date(inputDate));
    return date.format("YYYY/MM/DD");
  };

  const handleCompletePayment = (stripe_payment_id) => {
    let rental_unit_code = state?.rentalId ?? "";
    setPaymentSubmitLoader(true);
    let payload = {
      guest: {
        email: formikProps.values.email,
        first_name: formikProps.values.first_name,
        last_name: formikProps.values.last_name,
        phone: formikProps.values.phone,
        address: "-",
      },
      guest_nr: Number(guestCnt),
      check_in_date: formatDate(checkIn),
      check_out_date: formatDate(checkOut),
      paid_with: pay === "now" ? "card" : "cash",
      prepayment_amount: 0,
      subtotal: subTotal,
      total_amount: total,
      discount_price: totalDiscount,
      discount_id: discount_id,
      status: "pending",
      price_per_night_id: selectedGuests?.label ?? Number(selectedGuests),
      stripe_payment_id: pay === "now" ? stripe_payment_id : null,
    };

    WhiteLabelService.createBooking(payload, rental_unit_code)
      .then(() => {
        navigate("/rental/booking-success", {
          state: {
            checkInDate: formatDate(checkIn),
            checkOutDate: formatDate(checkOut),
            guest_num: guestCnt,
            discount_price: totalDiscount,
          },
        });
        setPaymentSubmitLoader(false);
        identifyVenue(app_key);
        trackEvent("Vacation Rental Book", {
          action_category: "Confirm reservation",
          action_outcome: "success",
          interaction_element: "button",
          source: "vb_web",
        });
      })
      .catch((error) => {
        setPaymentSubmitLoader(false);
        identifyVenue(app_key);
        trackEvent("Vacation Rental Book", {
          action_category: "Confirm reservation",
          action_outcome: "fail",
          interaction_element: "button",
          source: "vb_web",
        });

        NotificationManager.error("Failed", error.error);
      });
  };

  const handleEditGuest = () => {
    if (editGuest) setEditGuest(false);
    else setEditGuest(true);
  };

  const handleChangeDate = () => {
    setOpenModal(true);
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
    setTimeout(() => setOpenModal(false), 100);
  };

  const handleSaveModal = () => {
    setCheckIn(modalCheckIn.$d);
    setCheckOut(modalCheckOut.$d);
    handleCloseModal();
  };

  useEffect(() => {
    const startDate = new Date(checkIn);
    const endDate = new Date(checkOut);

    if (startDate > endDate) {
      NotificationManager.error(
        getAccommodationText("laterDates"),
        getAccommodationText("invalidDates")
      );
      return;
    }

    const differenceInMilliseconds = endDate - startDate;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (24 * 60 * 60 * 1000)
    );
    setDays(differenceInDays);
  }, [checkIn, checkOut]);

  useEffect(() => {
    const data = getPriceValue(selectedGuests?.value ?? selectedGuests);
    setUpdatedPrice(data?.price);
    setSubTotal(data?.price * days);
    setDiscountPrice(state?.header?.discount?.value ?? 0);

    if (state?.header?.discount?.type === "percentage") {
      setDiscountPrice = (
        total *
        (state?.header?.discount?.value / 100)
      ).toFixed(2);
    } else if (state?.header?.discount?.type === "fixed") {
      setDiscountPrice = state?.header?.discount?.value ?? 0;
    } else {
      setDiscountPrice = 0;
    }
    setTotalDiscount(setDiscountPrice);
    // setTotalDiscount(discountPrice);
    setTotal(data?.price * days - discountPrice);
  }, [guestCnt, checkIn, selectedGuests, days]);

  const appearance = {
    theme: "flat",
  };

  return (
    <div className="bg-white md:pb-8 mb-4 md:my-0 mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16 pb-5">
      <div className="flex flex-row items-center gap-6 mt-24">
        <BsChevronLeft
          className="font-bold text-xl cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <FormText title={rentalReserveText("confirmBooking")} type="title" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 mt-8 gap-20">
        <RentalBooking
          {...{
            checkIn,
            checkOut,
            editGuest,
            formikProps,
            guestCnt,
            handleChangeDate,
            handleEditGuest,
            handlePaymentChange,
            selectedGuests,
            setGuestCnt,
            setSelectedGuests,
            state,
            total,
            user,
            userData,
            setPay,
            pay,
          }}
        />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col border rounded-2xl p-6 h-max">
            <div className="flex flex-row items-center gap-4 pb-6 border-b h-full">
              <img
                src={state?.rentalImg}
                alt="reserve"
                className="rounded-xl w-32 h-24"
              />
              <div className="flex flex-col justify-between">
                <div className="flex flex-col">
                  <FormText
                    title="Entire rental unit"
                    type="subtitle-gray"
                    customClass="!text-sm"
                  />
                  <FormText
                    title={state?.rentalName ? state?.rentalName : ""}
                    customClass="!text-lg"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <FormText
                title={rentalReserveText("priceDetails")}
                type="itemtitle-sub-s"
                customClass="!font-medium !text-[22px] my-6"
              />
              <div className="flex flex-col gap-4">
                <div className="flex flex-col pb-5 border-b gap-2">
                  <div className="flex flex-row justify-between">
                    <FormText
                      title={`${state?.currency} ${updatedPrice} x ${days}`}
                      type="subtitle-link"
                    />
                    <FormText
                      title={`${state?.currency} ${subTotal}`}
                      type="subtitle-link"
                    />
                  </div>
                  <div className="flex flex-row justify-between">
                    <FormText
                      title={rentalReserveText("discount")}
                      type="subtitle-link"
                    />
                    <FormText
                      title={`${state?.currency} ${totalDiscount}`}
                      type="subtitle-link"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between gap-4">
                  <div className="flex flex-row items-center">
                    <FormText
                      title={rentalReserveText("total")}
                      type="subtitle-link"
                      customClass="!font-bold"
                    />
                    <FormText
                      title="(EUR)"
                      customClass="!font-bold underline"
                      type="subtitle-link"
                    />
                  </div>
                  <FormText
                    title={`${state?.currency} ${total}`}
                    type="subtitle-link"
                    customClass="!font-bold"
                  />
                </div>
              </div>
            </div>
          </div>
          {pay === "now" && clientSecret ? (
            <div className="flex flex-col border rounded-2xl p-6 h-max">
              <Elements
                options={{
                  appearance,
                  clientSecret,
                }}
                stripe={stripePromise}
              >
                <StripeCheckoutForm
                  onStripeCheckout={(paymentIntentId) =>
                    handleCompletePayment(paymentIntentId)
                  }
                  buttonClasses="rounded-[6px] bg-[#6DDFE2] h-14 text-white py-[10px] align-middle text-18 font-semibold leading-7 "
                  type="accommodation"
                  label="Complete payment"
                  className={`w-full rounded-[6px] bg-[#6DDFE2] h-14 text-white py-[10px] align-middle text-18 font-semibold leading-7 ${
                    isDisabled ? "cursor-not-allowed" : ""
                  }`}
                />
              </Elements>
            </div>
          ) : (
            <button
              disabled={isDisabled}
              className={`rounded-[6px] bg-[#6DDFE2] h-14 text-white py-[10px] align-middle text-18 font-semibold leading-7 ${
                isDisabled ? "cursor-not-allowed" : ""
              }`}
              onClick={handleCompletePayment}
            >
              {paymentSubmitLoader ? (
                <div className="text-white">
                  <CircularProgress size={24} />
                </div>
              ) : (
                rentalReserveText("completePayment")
              )}
            </button>
          )}
        </div>
      </div>

      <RentalReserveModal
        {...{
          handleCloseModal,
          handleSaveModal,
          modalCheckIn,
          modalCheckOut,
          openModal,
          setCheckIn,
          setCheckOut,
          setModalCheckIn,
          setModalCheckOut,
          show,
          bookingDate,
        }}
      />
    </div>
  );
};

export default RentalReserve;
