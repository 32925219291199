import React from "react";
import GoogleMapContainer from "../../../components/GoogleMapContainer";
import { getAccommodationText } from "../../venue-accommodation";

const RentalLocation = ({ location, header, openVR, vRlink }) => {
  return (
    <div className="w-full md:w-[70%] md:pr-[49px]">
      <h2 className="text-[#2D3748] text-24 md:text-[30px] font-bold leading-[120%] mb-6">
        {getAccommodationText("wehereIsLocated")}
      </h2>
      <div className="">
        <GoogleMapContainer location={location} address={header?.address} />
      </div>
      <div className="mt-[37px]">
        <p className="text-[#240b3b] text-18 md:text-20 font-medium leading-7 mb-4">
          {/* TODO Griseld, add in where_located, a new property for country and state, right now is address */}
          {header?.address}
        </p>
        {/*<a*/}
        {/*  className="text-[14px] leading-5 font-medium text-[#240b3b] cursor-pointer"*/}
        {/*  // onClick={() => openGoogleMaps()}*/}
        {/*>*/}
        {/*  /!*todo: redirect to google map with lat long and should be good*!/*/}
        {/*  Open in Google Maps {`>`}*/}
        {/*</a>*/}
      </div>
      {/* {vRlink && (
        <div className="mt-[17px]">
          <a
            className="text-[14px] leading-5 font-medium text-[#240b3b] cursor-pointer"
            // onClick={() => openVR()}
          >
            Virtual Tour {`>`}
          </a>
        </div>
      )} */}
    </div>
  );
};

export default RentalLocation;
