import React, { useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import DoubleCircleIcon from "../../../assets/svgs/double-circle.svg";
import BathroomIcon from "../../../assets/svgs/fluent-bathroom.svg";
import BedIcon from "../../../assets/svgs/fluent-bed.svg";
import ParkingIcon from "../../../assets/svgs/fluent-parking.svg";
import RoomIcon from "../../../assets/svgs/fluent-room.svg";
import { getAccommodationText } from "../../venue-accommodation";

const RentalOverview = ({ whatIncluded, getFacilityImageByValue }) => {
  const [extendedList, setExtendedList] = useState([]);
  const len = 5;

  const handleShowMoreLess = (category) => {
    if (extendedList.includes(category)) {
      setExtendedList((v) => v.filter((item) => item !== category));
    } else {
      setExtendedList((v) => [...v, category]);
    }
  };

  return (
    <div>
      <h2 className="text-[#2D3748] text-24 md:text-[30px] leading-[120%] font-bold mb-6">
        {getAccommodationText("whatIsincluded")}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-[47px]">
        <div className="rounded-[5px] border-[1px] border-[#CBD5E1] text-[#2D3748] p-6 flex flex-col">
          <img alt="" src={BedIcon} className="w-10 h-10" />
          <p className="text-16 font-bold leading-[120%] my-2">Beds</p>
          {whatIncluded?.beds?.map((item, key) => (
            <p key={key} className="text-14 font-medium leading-[24px]">
              {item}
            </p>
          ))}
        </div>
        <div className="rounded-[5px] border-[1px] border-[#CBD5E1] text-[#2D3748] p-6 flex flex-col flex-1">
          <img alt="" src={RoomIcon} className="w-10 h-10" />
          <p className="text-16 font-bold leading-[120%] my-2">Rooms</p>
          {whatIncluded?.rooms?.map((item, key) => (
            <p key={key} className="text-14 font-medium leading-[24px]">
              {item}
            </p>
          ))}
        </div>

        <div className="rounded-[5px] border-[1px] border-[#CBD5E1] text-[#2D3748] p-6 flex flex-col flex-1">
          <img alt="" src={BathroomIcon} className="w-10 h-10" />
          <p className="text-16 font-bold leading-[120%] my-2">Bathroom</p>
          <p className="text-14 font-medium leading-[24px]">
            {whatIncluded?.bathrooms ?? 0} bathroom
          </p>
        </div>

        <div className="rounded-[5px] border-[1px] border-[#CBD5E1] text-[#2D3748] p-6 flex flex-col flex-1">
          <img alt="" src={ParkingIcon} className="w-10 h-10" />
          <p className="text-16 font-bold leading-[120%] my-2">Parking</p>
          {whatIncluded?.parking?.map((item, key) => (
            <p key={key} className="text-14 font-medium leading-[24px]">
              {item}
            </p>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4  gap-5 md:gap-[47px] mt-[34px]">
        {whatIncluded &&
          whatIncluded.facilities &&
          Object.entries(whatIncluded.facilities).map(
            ([categoryName, items], index) => (
              <div key={index} className="flex flex-col gap-2 md:gap-4">
                <p className="text-14 md:text-16 leading-6 font-bold text-[#240b3b]">
                  {categoryName}
                </p>
                {items
                  ?.slice(
                    0,
                    extendedList.includes(categoryName) ? items.length : len
                  )
                  .map((item, s_index) => (
                    <div key={s_index} className="flex items-center">
                      <img
                        alt=""
                        src={
                          getFacilityImageByValue(item.name)
                            ? getFacilityImageByValue(item.name)
                            : DoubleCircleIcon
                        }
                      />
                      <p className="text-[#707070] leading-6 text-14 md:text-16 font-medium ml-4 ">
                        {item.name}
                      </p>
                    </div>
                  ))}
                {items?.length > len && (
                  <div
                    className="flex flex-row items-center gap-1 order-2 cursor-pointer"
                    onClick={() => handleShowMoreLess(categoryName)}
                  >
                    <span className="font-bold underline">
                      {!extendedList.includes(categoryName)
                        ? getAccommodationText("showMore")
                        : getAccommodationText("showLess")}
                    </span>
                    {!extendedList.includes(categoryName) ? (
                      <BsChevronRight className="font-bold" />
                    ) : (
                      <BsChevronLeft className="font-bold" />
                    )}
                  </div>
                )}
              </div>
            )
          )}
      </div>
    </div>
  );
};

export default RentalOverview;
