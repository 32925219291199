import { Select } from "antd";
import moment from "moment";
import React from "react";
import { BsFillRecordCircleFill } from "react-icons/bs";
import { FaRegCircle } from "react-icons/fa";
import FormText from "../../components/FormText";
import FormTextInput from "../../components/FormTextInput";
import "./index.css";
import { Link } from "react-router-dom";
import { rentalReserveText } from ".";

const RentalBooking = ({
  checkIn,
  checkOut,
  handleChangeDate,
  selectedGuests,
  setSelectedGuests,
  state,
  setGuestCnt,
  editGuest,
  guestCnt,
  handleEditGuest,
  formikProps,
  handlePaymentChange,
  total,
  user,
  userData,
  setPay,
  pay,
}) => {
  return (
    <div className="flex flex-col mb-6 gap-4">
      <div className="flex flex-col pb-6 border-b gap-6">
        <FormText
          title={rentalReserveText("yourBooking")}
          type="itemtitle-sub-s"
          customClass="!font-bold !text-[22px]"
        />
        <div className="flex flex-row items-start justify-between">
          <div className="flex flex-col gap-1">
            <FormText
              title={rentalReserveText("dates")}
              type="subtitle-link"
              customClass="!font-bold"
            />
            <FormText
              title={`${moment(new Date(checkIn)).format("MMM DD")} - ${moment(
                new Date(checkOut)
              ).format("MMM DD")}`}
              type="subtitle-link"
            />
          </div>
          <div onClick={handleChangeDate}>
            <FormText
              title={rentalReserveText("change")}
              type="subtitle-link"
              customClass="underline cursor-pointer !font-bold"
            />
          </div>
        </div>
        <div className="flex flex-row items-start justify-between">
          <div className="flex flex-col gap-1">
            <FormText
              title={rentalReserveText("guestsCapital")}
              type="subtitle-link"
              customClass="!font-bold"
            />
            <div className="flex flex-row items-center gap-3">
              {editGuest ? (
                <>
                  <Select
                    defaultValue={rentalReserveText("selectGuest")}
                    className="flex flex-1 w-full"
                    value={selectedGuests?.label}
                    onChange={(e, value) => {
                      setSelectedGuests({
                        value: value.children,
                        label: e,
                      });
                      setGuestCnt(value.children);
                    }}
                  >
                    {state?.guestList
                      .sort((a, b) => a?.label - b?.label)
                      .map((guest, index) => {
                        return (
                          <Select.Option key={index} value={guest.value}>
                            {guest?.label}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </>
              ) : (
                <div className="flex gap-1 items-center">
                  <FormText title={guestCnt} type="subtitle-link" />
                  <span>{rentalReserveText("guests")}</span>
                </div>
              )}
            </div>
          </div>
          <div onClick={() => handleEditGuest()}>
            <FormText
              title={
                editGuest
                  ? rentalReserveText("save")
                  : rentalReserveText("change")
              }
              type="subtitle-link"
              customClass="!font-bold underline cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col border-b gap-6 py-6">
        <FormText
          title={rentalReserveText("howToPay")}
          type="itemtitle-sub-s"
          customClass="!font-bold !text-[22px]"
        />

        <div className="flex flex-col ">
          <div
            className={`flex cursor-pointer flex-row justify-between p-4  ${
              pay === "now" ? "border-2 border-primary1" : "border"
            } rounded-t-xl`}
            onClick={() => {
              handlePaymentChange();
              setPay("now");
            }}
          >
            <div className="flex flex-col">
              <FormText
                title={rentalReserveText("payNow")}
                type="subtitle-link"
                customClass="!font-bold"
              />
              <FormText
                title={`${rentalReserveText("fullAmount")} (${
                  state?.currency
                } ${total} ) ${rentalReserveText("freeToGo")}`}
                type="subtitle-link"
              />
            </div>
            {pay === "now" ? <BsFillRecordCircleFill /> : <FaRegCircle />}
          </div>

          <div
            className={`flex flex-row cursor-pointer justify-between p-4 ${
              pay === "later" ? "border-2 border-primary1" : "border border-t-0"
            } rounded-b-xl mt-[-1px]`}
            onClick={() => setPay("later")}
          >
            <div className="flex flex-col">
              <FormText
                title={rentalReserveText("payLater")}
                type="subtitle-link"
                customClass="!font-bold"
              />
              <FormText
                title={rentalReserveText("paymentMethod")}
                type="subtitle-link"
              />
            </div>
            {pay === "later" ? <BsFillRecordCircleFill /> : <FaRegCircle />}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6 mt-2">
        <div className="flex flex-col justify-center pr-10">
          <FormText
            title={rentalReserveText("mainGuestDetails")}
            type="itemtitle-sub-s"
            customClass="!font-bold !text-[22px]"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-6 md:gap-y-4 gap-2 rounded-xl border border-[#D9D9D9] p-4">
          <FormTextInput
            placeholder={rentalReserveText("firstName")}
            value={formikProps.values.first_name}
            // error={formikProps.errors.first_name}
            enableLabel={false}
            inputClassName="h-10 md:h-12"
            onChange={(e) =>
              formikProps.setFieldValue("first_name", e.target.value)
            }
          />
          <FormTextInput
            placeholder={rentalReserveText("lastName")}
            value={formikProps.values.last_name}
            // error={formikProps.errors.last_name}
            enableLabel={false}
            inputClassName="h-10 md:h-12"
            onChange={(e) =>
              formikProps.setFieldValue("last_name", e.target.value)
            }
          />
          <FormTextInput
            placeholder={rentalReserveText("phoneNumber")}
            value={formikProps.values.phone}
            // error={formikProps.errors.phone}
            enableLabel={false}
            inputClassName="h-10 md:h-12"
            onChange={(e) => formikProps.setFieldValue("phone", e.target.value)}
          />
          <FormTextInput
            placeholder={rentalReserveText("email")}
            value={formikProps.values.email}
            // error={formikProps.errors.email}
            enableLabel={false}
            inputClassName="h-10 md:h-12"
            onChange={(e) => formikProps.setFieldValue("email", e.target.value)}
          />
        </div>

        <div className="text-end text-sm">
          {!user && !userData ? (
            <div className="text-end text-sm">
              <span>
                {rentalReserveText("alreadyHaveAccount")}{" "}
                <Link
                  to={{ pathname: "/login", search: "src=rental" }}
                  state={state}
                  className="underline underline-offset-2"
                >
                  {rentalReserveText("login")}
                </Link>
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RentalBooking;
