import { customerText } from "../Seo/whitelabel/bybest/bybest-customer-layout";

const statusStyles = {
  success: {
    textColor: "text-[#027A48]",
    bgColor: "bg-[#ECFDF3]",
  },
  error: {
    textColor: "text-[#FF3E3E]",
    bgColor: "bg-[#FDEFEC]",
  },
  info: {
    textColor: "text-[#3E45FF]",
    bgColor: "bg-[#EDEEFF]",
  },
  default: {
    textColor: "text-[#344054]",
    bgColor: "bg-[#F2F4F7]",
  },
};

export const getStatus = (status) => {
  let style = statusStyles.default;

  if (status === 1) {
    style = statusStyles.success;
  } else if (status === 0) {
    style = statusStyles.error;
  } else if (status === 2) {
    style = statusStyles.info;
  }

  const statusValue = () => {
    if (typeof status === "number") {
      return status === 1
        ? customerText("active")
        : status === 0
        ? customerText("inactive")
        : customerText("pending");
    }
    return status;
  };

  return (
    <div>
      <div
        className={`flex ${style.textColor} ${style.bgColor} px-2 py-[2px] rounded-[16px] text-12 font-medium leading-[18px]`}
      >
        <span className="text-[20px] content-center mr-1">•</span>
        {statusValue()}
      </div>
    </div>
  );
};
